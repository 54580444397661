import { Box, system, SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';
import { variant, ResponsiveValue } from 'styled-system';

export const headingVariants = {
  h1: {
    fontSize: [4, 6],
    textTransform: 'uppercase',
  },
  h2: {
    fontSize: [3, 5],
    textTransform: 'uppercase',
  },
  h3: {
    fontSize: [3, '28px'],
  },
  h4: {
    fontSize: [2.5, 3],
  },
  h5: {
    fontSize: [2, 2.25],
  },
  h6: {
    fontSize: 2,
  },
  h7: {
    fontSize: 1.5,
    fontWeight: 'normal',
  },
};

type CustomProps = {
  variant:
    | ResponsiveValue<keyof typeof headingVariants>
    | keyof typeof headingVariants;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & SystemProps;

export const Heading = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? (typeof props?.variant === 'string' ? props?.variant : 'h1'),
}))<CustomProps>`
  font-family: ${(p) => p.theme.fonts.heading};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: ${(p) => p.theme.lineHeights.medium};

  ${variant({ variants: headingVariants })}
  ${system};
`;
