import React, { FC } from 'react';
import { Box, Flex, SystemProps } from '@storyofams/react-ui';

export const Divider: FC<SystemProps & { vertical?: boolean }> = ({
  children,
  color = 'grey200',
  vertical,
  ...props
}) => {
  return vertical ? (
    <Flex flexDirection="column">
      <Flex
        flex={1}
        height="100%"
        width="1px"
        bg="grey800"
        opacity={0.5}
        {...props}
      />
    </Flex>
  ) : (
    <Flex width="100%" alignItems="center" {...props}>
      <Box
        width="100%"
        minHeight="1px"
        maxHeight="1px"
        backgroundColor={color}
        flex={1}
      />
      {children && (
        <Flex mx={2} flexShrink={0}>
          {children}
        </Flex>
      )}
      <Box
        flex={1}
        width="100%"
        minHeight="1px"
        maxHeight="1px"
        backgroundColor={color}
      />
    </Flex>
  );
};
