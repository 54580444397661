import Head from "next/head";
import { useRouter } from "next/router";
import { NextSeo, NextSeoProps } from "next-seo";

import { seo } from "~config";
import { getDescription } from "~lib";

interface SeoProps extends NextSeoProps {
  story?: any;
  image?: string;
  ogType?: "website" | "article";
  noIndex?: boolean;
  defaultTemplate?: boolean;
}

const messages = {
  title: "Natural Heroes",
};
export const Seo = ({
  story: storyProp,
  title: titleProp,
  description: descriptionProp,
  defaultTemplate = false,
  image: imageProp,
  ogType,
  noIndex,
  ...props
}: SeoProps) => {
  const router = useRouter();
  const { asPath, basePath, defaultLocale, pathname, locale, locales } = router;

  let title = titleProp;
  let description = descriptionProp;
  let image = { url: imageProp, width: 1200, height: 627 };

  const story = storyProp?.content || storyProp;

  if (story) {
    if (!title) {
      title = story.seo?.title || story.title;
    }
    if (!description) {
      description = getDescription(story);
    }
    if (!imageProp) {
      image = {
        url:
          story?.seo?.og_image ||
          story?.image?.filename ||
          story?.thumbnail?.filename,
        width: 1200,
        height: 627,
      };
    }
  }

  const isHome = locales
    .map((l) => `/${defaultLocale !== l ? `${l}/` : ""}`)
    .includes(pathname);

  if (title && isHome) {
    title = `${title}`;
  } else {
    title = title ?? messages.title;
  }
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={`https://www.naturalheroes.nl${basePath}${asPath?.replace(
          /(\?|#).*/,
          ""
        )}`}
        noindex={
          story?.noIndex === true ||
          noIndex ||
          process.env.NODE_ENV === "development" ||
          process.env.NEXT_PUBLIC_PREVIEW === "preview"
        }
        nofollow={
          story?.noIndex === true ||
          noIndex ||
          process.env.NODE_ENV === "development" || 
          process.env.NEXT_PUBLIC_PREVIEW === "preview"
        }
        openGraph={{
          images: [image],
          type: ogType || "website",
          locale,
          site_name: "Natural Heroes",
        }}
        titleTemplate={isHome || defaultTemplate ? "%s" : seo.titleTemplate}
        {...props}
      />
      <Head>
        {locales
          ?.filter((l) => l !== defaultLocale)
          ?.map((content) => (
            <meta
              key={content}
              property="og:locale:alternate"
              content={content}
            />
          ))}
      </Head>
    </>
  );
};
