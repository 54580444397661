import React, { forwardRef, Ref, useState, useEffect } from "react";

import { InputWrapperProps, Icon, Box } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { useId } from "react-id-generator";
import type { PolymorphicForwardRefExoticComponent } from "react-polymorphic-types";
import styled from "styled-components";
import { Eye } from "../../../components/common/Icons";
import { InputWrapper } from "../Forms";

const _defaultElement = "input";

type CustomProps = {
  icon?: any;
  onIconClick?(): void;
  id?: string;
  disabled?: boolean;
  type?: string;
} & InputWrapperProps;

export const StyledInput = styled(Box)`
  appearance: none;
  display: inline-block;
  background-color: white;

  min-height: 48px;
  width: 100%;

  border-width: 1px;
  border-style: solid;

  transition: border-color 0.18s ease-in-out, background-color 0.18s ease-in-out;

  text-decoration: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey400};
    text-overflow: ellipsis;
  }

  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.colors.grey800};
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const Input: PolymorphicForwardRefExoticComponent<
  CustomProps,
  typeof _defaultElement
> = forwardRef(
  (
    { icon, onIconClick, ...props }: CustomProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const {
      label,
      status,
      statusMessage,
      error,
      height = "48px",
      disabled,
      id: givenId,
    } = props;
    const autoId = useId();
    const id = givenId || `input-${autoId}`;
    const [type, setType] = useState("text");

    useEffect(() => {
      if (props?.type) {
        setType(props?.type);
      }
    }, [props?.type]);

    return (
      <InputWrapper
        {...pick(props)}
        label={label}
        statusMessage={statusMessage}
        status={status}
        error={error}
        id={id}
        type="input"
      >
        <Box position="relative">
          <StyledInput
            as={_defaultElement}
            id={id}
            ref={ref}
            disabled={disabled}
            px={2}
            py={1.5}
            pr={icon && 5}
            height={height}
            fontSize={2}
            color="black"
            borderColor={error || status === "error" ? "error600" : "grey400"}
            {...omit(props)}
            borderRadius="md"
            type={type}
          />

          {(!!icon || props?.type === "password") && (
            <Icon
              display="flex"
              alignItems="center"
              position="absolute"
              right={1.5}
              top={0}
              bottom={0}
              opacity={disabled ? 0.6 : 1}
              color="grey500"
              fontSize={3}
              {...(props.type === "password"
                ? {
                    icon: Eye,
                    onClick: () =>
                      setType(type === "password" ? "text" : "password"),
                    as: "button",
                    type: "button",
                  }
                : onIconClick
                ? {
                    onClick: onIconClick,
                    as: "button",
                    type: "button",
                  }
                : {
                    pointerEvents: "none",
                  })}
              {...(icon ? (icon?.icon ? icon : { icon }) : {})}
            />
          )}
        </Box>
      </InputWrapper>
    );
  }
);
